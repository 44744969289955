<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">优惠券包列表</span>
            <div class="a-flex-rcc">
                <el-button icon="el-icon-plus" type="primary" @click="editCouponPackage('','add')">创建券包</el-button>
            </div>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input label="券包ID" v-model="pageParam.params.goodsSn" />
                <le-input label="券包名称" v-model="pageParam.params.goodsName" />
            </le-search-form>
            <le-pagview ref="couponPackageListPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.couponPackageList">
                <el-table ref="couponPackageList" class="couponPagelist" :data="tableData" :highlight-current-row="true"  style="width: 100%" v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column label="ID" prop="id" min-width="100"></el-table-column>
                    <el-table-column label="优惠券券包" prop="name" min-width="160"></el-table-column>
                    <el-table-column label="描述" prop="goodsDesc" min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.goodsDesc || '-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="划线价"
                        min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.oldPrice) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="售价"
                        min-width="100">
                        <template slot-scope="{ row }">
                            <span>￥{{ util.numFormat(row.price) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="状态"
                        min-width="140">
                        <template slot-scope="scope">
                            <el-switch
                                @change="changeGoodsStatus(scope.row,scope.$index)"
                                v-model="scope.row.goodsStatus"
                                :inactive-value="0"
                                :active-value="1"
                                active-color="#409EFF"
                                inactive-color="#F56C6C">
                            </el-switch>
                            <span class="a-mlr-12" :class="scope.row.goodsStatus?'a-c-blue':'a-c-error'">已{{ scope.row.goodsStatus | initDic(goodsStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="商品类型"
                        min-width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.goodsType | initDic(goodsTypeDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="包含优惠券" prop="remark" min-width="100">
                        <template slot-scope="{ row }">
                            <span>{{ row.goodsDetails.name }} *{{ row.num }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="创建时间" prop="createTime" min-width="180"></el-table-column>
                    <el-table-column prop="name" label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-button type="text" @click="editCouponPackage(scope.row, 'readOnly')">查看</el-button>
                            <el-button type="text" @click="editCouponPackage(scope.row, 'edit')">编辑</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal a-plr-10">条记录</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
    </div>
</template>

<script>
import util from '../../../src/utils/util'
    export default {
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.goodsPage,
                    method: "post",
                    params: {
                        goodsSn: '',//
                        goodsStatus: '', //
                        goodsName: '',
                    },
                    freshCtrl: 1,
                },
                pageSize: 20,
                pageNum: 1,
                goodsStatusDic: [],
                goodsTypeDic: []
            }
        },
        created () {
            this.$getDic('goodsStatus').then(res=>{ this.goodsStatusDic = res; })
            this.$getDic('goodsType').then(res=>{ this.goodsTypeDic = res; })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },  
        methods: {
            //获取列表
            setTableData(data) {
                this.tableData = data.map(item=>{
                    return {
                        ...item,
                        goodsDetails: item.goodsDetails ? JSON.parse(item.goodsDetails) : {}
                    }
                });
            },
            handlerRest() {
                this.pageParam.params = {
                    goodsSn: '',//
                    goodsStatus: '', //
                    goodsName: '',
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['couponPackageListPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            changeGoodsStatus (datas, index) {
                let txt
                if(datas.goodsStatus == 1){
                    txt = '是否确定上架该券包？'
                }else{
                    txt = '是否确定下架该券包？'
                }
                this.$confirm(txt, '温馨提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(_=>{
                    this.$Axios._post({
                        url: this.$Config.apiUrl.goodsUpDown,
                        params: {
                            "goodsStatus": datas.goodsStatus,
                            "id": datas.id
                        }
                    }).then((res) => {
                        this.$message.success('操作成功')
                        this.pageParam.freshCtrl++;
                    }).catch((error) => {
                        this.pageParam.freshCtrl++;
                    })
                }).catch(_=>{
                    this.tableData[index].goodsStatus = this.tableData[index].goodsStatus == 0 ? 1 : 0
                })
            },
            editCouponPackage (datas, type) {
                this.$router.push({
                    path: '/goods/goods-edit',
                    query: {
                        id: datas?datas.id:'',
                        editType: type
                    }
                })
            },

        }
    }
</script>

<style lang="scss" scoped>
.couponPagelist{
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        /*width: 0;宽度为0隐藏*/
        width: 0 !important;
        height: 0 !important;
    }
}

/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
.stationTag{
    padding: 0px 6px;
    background: #f4f4f5;
    color: #909399;
    border: 1px solid #e9e9eb;
    border-radius: 3px;
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 12px;
    word-break: keep-all;
}
</style>